// app.js

import $ from 'jquery';
import 'what-input';
import 'imagesloaded';

import Util from './module/Util';
const util = new Util();

import Menu from './module/Menu';
const menu = new Menu($('.g-headerBtn'));

import ReadMore from './module/ReadMore';

import Accordion from './module/Accordion';

import Common from './page/Common';
const common = new Common();

import Home from './page/Home';
const home = new Home();

import Facilities from './page/Facilities';
const facilities = new Facilities();

$(function(){
  common.onready();
  
  $('.js-accordion').each(function(){
    new Accordion($(this));
  });

  if( $('.homeContents').length > 0 ) home.onready();

  facilities.onready();

  if( $('.js-readMoreBtn').length > 0 ) new ReadMore('.js-readMoreBtn');
});

$(window).on('load', function(){
  common.onload();
});