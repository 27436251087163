// Menu

'use strict';

import $ from 'jquery';

export default class {
  constructor(elem) {
    var self = this;

    this.$elem = elem;
    this.$body = $('body');
    this.openClass = 'menu--open';
    this.$menu = $('#menu');
    this.$navigation = $('#GlobalNavigation');

    this.$elem.on('click', function(){
      if( self.$body.hasClass(self.openClass) ){
        self.close();

        self.$elem.attr('aria-expanded', false);
        self.$menu.attr('aria-hidden', true);
        self.$navigation.attr('aria-hidden', true);
      }
      else {
        self.open();

        self.$elem.attr('aria-expanded', true);
        self.$menu.attr('aria-hidden', false);
        self.$navigation.attr('aria-hidden', false);
      }
    });
  }

  open(){
    var self = this;
    this.$menu.addClass('show');
    this.$elem.addClass('close');
    
    setTimeout(function(){
      self.$body.addClass(self.openClass);
    }, 50);
  }

  close(){
    var self = this;
    this.$body.removeClass(this.openClass);
    this.$elem.removeClass('close');

    setTimeout(function(){
      self.$menu.removeClass('show');
    }, 200);
  }
}