// Home

'use strict';

import Vivus from 'vivus';
import anime from 'animejs';
import $ from 'jquery';

export default class {
  constructor() {

    this.body = document.querySelector('body');
    this.video = document.getElementById('MainVideo');
    this.loading = document.getElementById('Loading');
    this.home = document.getElementById('Home');
    this.header = document.getElementById('GlobalHeader');
    this.initialPlay = false;

    let perfEntries = performance.getEntriesByType("navigation");
    perfEntries.forEach( (p) => { this.type = p.type; });
  }

  onready(){
    let self = this;

    if ( this.type === 'reload' ) { sessionStorage.removeItem('loading'); }
    
    if( !sessionStorage.getItem('loading') ){
      this.body.classList.add('is-loading');
      setTimeout( () => {
        // this.video.addEventListener('canplay', _show, false);
        _show();
      }, 3000); // GIFアニメ待ち
    }
    else {
      this.loading.remove();
      this.home.style.opacity = 1;
      this.header.style.opacity = 1;
      this.maskanime();
    }

    function _show(){
      sessionStorage.setItem('loading', true);

      if (self.initialPlay) return;
      self.initialPlay = true;
          
      anime({
        targets: '#Loading',
        opacity: [1,0],
        duration: 1000,
        begin: function(anim){
          self.body.classList.remove('is-loading');
        },
        complete: function(anim){
          self.loading.remove();
        }
      });

      anime({
        targets: '#Home',
        opacity: [0,1],
        duration: 1000,
        complete: function(){
          self.maskanime();
        }
      });

      anime({
        targets: '.g-header',
        opacity: [0,1],
        duration: 1000,
        complete: function(){
          self.maskanime();
        }
      });

      self.video.removeEventListener('canplay', _show, false);
    }
  
    //
    anime({
      targets: ".p-homeImageSliderMain--1",
      duration: 28000,
      translateX: -1535,
      easing: 'linear',
      loop: true
    });
  
    anime({
      targets: ".p-homeImageSliderMain--2",
      duration: 29000,
      translateX: 1338,
      easing: 'linear',
      loop: true
    });
    
  }

  maskanime(){
    new Vivus('MainMask', {
      type: "scenario-sync",
      duration: 10,
      forceRender: !1,
      animTimingFunction: Vivus.EASE
    });
  }
}