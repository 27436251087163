// Common

'use strict';

import 'slick-carousel';
import SmoothScroll from 'smooth-scroll';
import $ from 'jquery';

export default class {
  constructor() {
    //
    new SmoothScroll('a[data-smooth]', {
      speed: 400,
      easing: 'easeInOutQuart',
      speedAsDuration: true,
      updateURL: false,
      header: '[data-header]'
    });
  }

  onready(){
    //
    $(window).on('scroll', function(){
      if( $(window).scrollTop() > window.innerHeight ){
        if( !$('body').hasClass('top--scrolled') ){
          $('body').addClass('top--scrolled');
        }
      }
      else {
        $('body').removeClass('top--scrolled'); 
      }
    });
  }

  onload(){
    //
    $('.js-scroll').each(function(){
      let $this = $(this);
      let offset = $(this).offset().top;

      $(window).on('scroll', function(){
        if( $(window).scrollTop() + window.innerHeight*3/5 > offset ){
          if( !$this.hasClass('animation') ){
            $this.addClass('animation');
          }
        }
      })
    });
  }
}