// Facilities

'use strict';

import 'slick-carousel';
import $ from 'jquery'

export default class {
  constructor() {
    var self = this;
  }

  onready(){
    let self = this;

    $('.p-facilityListItem').each(function(){
      let $this = $(this);
      let id = $this.attr('id');
      let $mainslider = $('.p-facilityListImageMain', $this);
      let $thumbslider = $('.p-facilityListImageThumb', $this);

      $mainslider.slick({
        fade: true,
        arrows: true,
        dots: false,
        asNavFor: '#'+id+' .p-facilityListImageThumb'
      });

      $thumbslider.slick({
        slidesToScroll: 1,
        slidesToShow: 3,
        dots: true,
        centerMode: true,
        arrows: false,
        focusOnSelect: true,
        dots: false,
        asNavFor: '#'+id+' .p-facilityListImageMain'
      });
    });
  }
}