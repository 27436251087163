// Accordion

'use strict'

import $ from 'jquery';

export default class {
  constructor($item){
    let self = this;

    self.$accordion = $item;
    self.$btn = self.$accordion.find('.js-accordion__btn');
    self.$content = self.$accordion.find('.js-accordion__content');
    self.$contentInner = self.$content.find('div');

    self.$btn.on('click', function(e){
      e.preventDefault();

      if( self.$content.height() == 0 ){
        self.open();

        self.$content.attr('aria-hidden', false);
        self.$content.attr('aria-expanded', true);
      }
      else {
        self.close();

        self.$content.attr('aria-hidden', true);
        self.$content.attr('aria-expanded', false);
      }
    });
  }

  open(){
    this.$accordion.addClass('open');
    this.$content.show().focus();
    this.$content.css({
      height: this.$contentInner.innerHeight()
    });
  }

  close(){
    let self = this;

    this.$accordion.removeClass('open');
    this.$content.css({
      height: 0
    });

    setTimeout(function(){
      self.$content.hide();
    }, 300);
  }
}