// ReadMore

'use strict'

import anime from 'animejs';
import $ from 'jquery';

export default class {
  constructor(elem){
    let self = this;

    this.$elem = $(elem);
    
    this.target = this.$elem.data('target');
    this.$target = $(this.target);
    this.$item = this.$target.find('.js-readMoreItem');

    this.btnpos = this.$elem.offset().top;
    this.infinite = this.$elem.data('infinite');

    this.maxnum = this.$item.length;
    this.num = this.$elem.data('num') || 6;
    this.step = this.$elem.data('step') || 6;
    this.prev = 0;

    this.$item.slice(this.prev,this.num).show();
    this.$target.addClass('show');

    this.$elem.on('click', (e) => {
      e.preventDefault();
      this.add();
    });

    if( this.infinite ){
      $(window).on('scroll', (e) => {
        if( $(window).scrollTop() + (window.innerHeight * 4/5) > this.btnpos ){
          this.add();
        }
      })
    }
  }

  add(){
    this.prev = this.num;
    this.num += this.step;

    this.$item.slice(this.prev,this.num).show();

    this.btnpos = this.$elem.offset().top;
    if( this.maxnum <= this.num ){ this.end(); }
  }

  end(){
    this.$elem.hide();
  }
}